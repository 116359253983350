@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.container {
  width: 100%;
  position: relative;

  @include screen('mobile') {
  }

  .required-star {
    position: absolute;
    right: vw_d(-4);
    top: vw_d(-9);
    margin: 0;
    font-size: vw_d(16);
    line-height: vw_d(21);
    font-family: $narkiss-light-font;
    color: #000000;
    @include screen('mobile') {
      right: vw(5);
      top: vw(-15);
      margin: 0;
      font-size: vw(16);
      line-height: vw(21);
    }
  }
  .words-limit {
    position: absolute;
    bottom: vw_d(8);
    right: vw_d(14);
    color: $gray;
    font-family: $narkiss-regular-font;
    font-size: vw_d(18);
    font-weight: normal;

    @include screen('mobile') {
      font-size: vw(18);
      bottom: vw(10);
      right: vw(11);
    }
  }
  .textarea {
    display: block;
    height: vw_d(175);
    width: 100%;
    border-radius: vw_d(16);
    border: solid 1px $black;
    background-color: rgba(255, 255, 255, 0.4);
    padding: vw_d(12) vw_d(13) 0 vw_d(12);
    resize: none;
    font-family: $narkiss-bold-font;
    font-size: vw_d(16);
    line-height: vw_d(18);
    color: $black;
    outline: none;

    @include screen('mobile') {
      width: 100%;
      height: vw(390);
      padding: vw(14) vw(15);
      font-size: vw(18);
      line-height: vw(20);
      border-radius: vw(23);
      border: solid vw(1) $black;
    }

    &::placeholder {
      color: $black;
    }
  }

  .words-count {
    font-size: vw_d(16);
    line-height: vw_d(18);
    font-family: $narkiss-regular-font;
    @include screen('mobile') {
      font-size: vw(16);
      line-height: vw(18);
    }
  }

  .error {
    display: block;
    color: red;
    font-size: vw_d(14);
    margin-right: vw_d(5);
    margin-top: vw_d(2);
    font-family: $narkiss-regular-font;

    @include screen('mobile') {
      font-size: vw(14);
      margin-right: vw(5);
    }
  }
}
