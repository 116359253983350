@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.container {
  width: 100%;
  position: relative;
  .required-star {
    position: absolute;
    right: vw_d(-4);
    top: vw_d(-9);
    margin: 0;
    font-size: vw_d(16);
    line-height: vw_d(21);
    font-family: $narkiss-light-font;
    color: #000000;
    @include screen('mobile') {
      right: vw(5);
      top: vw(-15);
      margin: 0;
      font-size: vw(16);
      line-height: vw(21);
    }
  }
  .form-input {
    display: block;
    width: 100%;
    height: vw_d(32);
    padding: 0 vw_d(8.5);
    margin: 0;
    line-height: vw_d(30);
    font-family: $narkiss-bold-font;
    font-size: vw_d(16);
    color: $black;
    background-color: rgba(255, 255, 255, 0.4);
    border: solid 1px #000;
    border-radius: vw_d(16);
    outline: none;
    -webkit-appearance: none;
    &::placeholder {
      color: $black;
      font-family: $narkiss-bold-font;
    }

    &:autofill {
      border: solid vw_d(1) #000;
      @include screen('mobile') {
        border: solid 1px #000;
      }
    }

    &:-webkit-autofill {
      border: solid vw_d(1) #000;
      @include screen('mobile') {
        border: solid 1px #000;
      }
    }

    @include screen('mobile') {
      width: 100%;
      height: vw(46);
      padding: vw(0) vw(15);
      font-size: vw(18);
      border-radius: vw(23);
      border: solid 1px #000;
    }
  }

  span {
    color: red;
    font-size: vw_d(14);
    margin-right: vw_d(5);
    margin-top: vw_d(2);
    font-family: $narkiss-regular-font;
    display: block;

    @include screen('mobile') {
      font-size: vw(14);
      margin-right: vw(5);
    }
  }
}
