@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

footer {
  position: absolute;
  bottom: vw_d(7);
  left: 50%;
  transform: translate(-50%, 0);
  @include screen('mobile') {
    position: relative;
    left: auto;
    bottom: auto;
    transform: translate(0, 0);
    padding: vw(68) 0;
  }
  .footer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen('mobile') {
      width: 100%;
      flex-wrap: wrap;
    }

    a {
      font-size: vw_d(15.7);
      letter-spacing: vw_d(-0.7);
      color: $black;
      font-family: $narkiss-light-font;
      cursor: pointer;

      @include screen('mobile') {
        font-size: vw(15.7);
        line-height: vw(17.27);
      }
    }

    span {
      width: vw_d(1);
      height: vw_d(16);
      margin: 0 vw_d(5);
      background-color: $black;
      @include screen('mobile') {
        width: vw(1);
        height: vw(16);
        margin: 0 vw(5);
      }
    }
  }
}
