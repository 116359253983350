@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'FbOgen-Black';
  src: url('/src/routes/client/assets/fonts/FbOgen-Black.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FbOgen-Bold';
  src: url('/src/routes/client/assets/fonts/FbOgen-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FbOgen-Regular';
  src: url('/src/routes/client/assets/fonts/FbOgen-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock-Bold';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock-Medium';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock-Semibold';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Semibold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock-Regular';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NarkissBlock-Light';
  src: url('/src/routes/client/assets/fonts/NarkissBlock-Light.ttf');
  font-weight: normal;
  font-style: normal;
}
