@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.form-wrapper {
  position: relative;
  width: vw_d(939);
  padding: 0;
  margin: 0 auto;
  z-index: 4;
  @include screen('mobile') {
    width: 100%;
    padding: 0 vw(23);
  }

  .form {
    position: relative;
    z-index: 2;
    form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .fields-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: vw_d(16);
        @include screen('mobile') {
          width: 100%;
          margin-bottom: vw(7);
          flex-wrap: wrap;
        }
        .field-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &.long {
            width: 100%;
          }
          &.short {
            width: vw_d(217);
            @include screen('mobile') {
              width: 100%;
            }
          }

          &.checkbox-wrapper {
            @include screen('mobile') {
              padding-top: vw(4);
            }
          }

          @include screen('mobile') {
            margin-bottom: vw(23);
          }

          .check-box-wrapper {
            // width: 48%;
          }
        }
      }

      .submit-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 0;
        @include screen('mobile') {
          flex-wrap: wrap;
          padding-top: vw(0);
          margin-top: vw(-7);
        }
        .confirmation {
          padding-top: vw_d(20);
          @include screen('mobile') {
            padding-top: 0;
          }
        }
      }
    }
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include screen('mobile') {
      top: auto;
      bottom: 5%;
      transform: translate(-50%, 0);
    }
  }
}
