@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import 'modern-css-reset/dist/reset.css';
@font-face {
  font-family: "FbOgen-Black";
  src: url("/src/routes/client/assets/fonts/FbOgen-Black.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FbOgen-Bold";
  src: url("/src/routes/client/assets/fonts/FbOgen-Bold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FbOgen-Regular";
  src: url("/src/routes/client/assets/fonts/FbOgen-Regular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NarkissBlock-Bold";
  src: url("/src/routes/client/assets/fonts/NarkissBlock-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NarkissBlock-Medium";
  src: url("/src/routes/client/assets/fonts/NarkissBlock-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NarkissBlock-Semibold";
  src: url("/src/routes/client/assets/fonts/NarkissBlock-Semibold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NarkissBlock-Regular";
  src: url("/src/routes/client/assets/fonts/NarkissBlock-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NarkissBlock-Light";
  src: url("/src/routes/client/assets/fonts/NarkissBlock-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 100;
  font-family: "FbOgen-Regular", sans-serif;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  body {
    min-height: inherit;
  }
}
body[class^=no-scroll-] {
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--ria-input-color);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  text-align: center;
  color: inherit;
}

/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */