@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: vw_d(256);
  min-width: vw_d(256);
  height: vw_d(39);
  color: #fff;
  font-size: vw_d(24);
  font-family: $narkiss-bold-font;
  background-color: #e31b23;
  border-radius: vw_d(20);
  @include screen('mobile') {
    width: 100%;
    height: vw(45);
    font-size: vw(30);
    border-radius: vw(23);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &.disabled {
    pointer-events: none;
    background-color: #b0c4cb !important;
  }
}
