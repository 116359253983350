@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.main {
  position: relative;
  min-height: 100vh;
  padding-bottom: vw_d(70);
  background-color: #fff;

  @include screen('mobile') {
    padding-bottom: vw(24);
    background-image: url('routes/client/assets/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  .desktop-images {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include screen('mobile') {
      display: none;
    }

    .left {
      position: absolute;
      top: vw_d(79);
      bottom: 0;
      left: 0;
      width: vw_d(462);
      background-size: cover;
      background-position: 100% 0%;
      background-repeat: no-repeat;
      background-image: url('routes/client/assets/desktop-left.png');
    }
    .right {
      position: absolute;
      top: vw_d(79);
      bottom: 0;
      right: 0;
      width: vw_d(453);
      background-size: cover;
      background-position: 0% 0%;
      background-repeat: no-repeat;
      background-image: url('routes/client/assets/desktop-right.jpg');
    }
  }
}
