@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.top-section {
  position: relative;
  padding: vw_d(103) 0 vw_d(43);
  background-color: transparent;
  z-index: 3;
  @include screen('mobile') {
    padding: vw(50) 0 vw(24);
  }

  .mobile-images {
    display: none;
    @include screen('mobile') {
      display: flex;
      width: 100%;
    }

    .first,
    .second {
      @include screen('mobile') {
        width: 50%;
        height: vw(158);
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }

    .first {
      background-image: url('src/routes/client/assets/top/mobileFirst.png');
    }
    .second {
      background-image: url('src/routes/client/assets/top/mobileSecond.png');
    }
  }

  .info {
    width: vw_d(939);
    margin: 0 auto;

    @include screen('mobile') {
      width: 100%;
      padding-top: vw(14);
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: vw_d(23);
      @include screen('mobile') {
        display: block;
        padding: 0 vw(12) vw(15);
      }
      .logo-wrapper {
        width: vw_d(158);
        margin-left: vw_d(16);
        margin-right: vw_d(-8);
        margin-top: vw_d(-13);
        @include screen('mobile') {
          width: vw(139);
          margin: 0 auto;
        }
        img {
          display: block;
          width: 100%;
          @include screen('mobile') {
          }
        }
      }
      .text {
        text-align: right;
        @include screen('mobile') {
          width: 100%;
          margin-top: vw(-2);
          text-align: center;
        }

        span {
          font-size: vw_d(58);
          line-height: vw_d(37);
          font-family: $narkiss-bold-font;

          @include screen('mobile') {
            font-size: vw(34.36);
            line-height: vw(21.92);
          }

          &.red {
            color: #e31b23;
          }
          &.black {
            color: #000;
          }
        }
      }
    }
    .paragraph-first {
      padding-bottom: vw_d(27);
      font-size: vw_d(24);
      line-height: vw_d(26);
      letter-spacing: vw_d(-0.4);
      color: #000;
      font-family: $narkiss-bold-font;
      text-align: center;
      @include screen('mobile') {
        padding: 0 vw(22) vw(23);
        font-size: vw(20);
        line-height: vw(25);
        letter-spacing: 0;
      }
    }

    .paragraph-second,
    .paragraph-third {
      font-size: vw_d(23);
      line-height: vw_d(26);
      color: #000;
      font-family: $narkiss-regular-font;
      text-align: center;
      @include screen('mobile') {
        padding: 0 vw(22);
        font-size: vw(20);
        line-height: vw(24);
        letter-spacing: vw(-0.4);
      }
    }

    .paragraph-second {
      padding-bottom: vw_d(2);
      @include screen('mobile') {
        padding-bottom: 0;
      }
    }

    .paragraph-third {
      padding-bottom: vw_d(23);
      @include screen('mobile') {
        padding-bottom: vw(23);
      }
    }

    .paragraph-forth {
      font-size: vw_d(24);
      line-height: vw_d(26);
      letter-spacing: vw_d(-0.4);
      color: #000;
      font-family: $narkiss-bold-font;
      text-align: center;
      @include screen('mobile') {
        padding: 0 vw(38);
        font-size: vw(22);
        line-height: vw(25);
        letter-spacing: 0;
      }
    }
  }
}
