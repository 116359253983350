/* stylelint-disable no-descending-specificity */
@import 'src/routes/client/styles/index.scss';
@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.89;
    background-color: #282928;
    z-index: 1;
  }

  .content {
    position: relative;
    width: vw_d(566);
    padding: vw_d(45) 0;
    height: auto;
    min-height: vw_d(262);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @include screen('mobile') {
      width: vw(288);
      height: auto;
      min-height: vw(131);
      padding: vw(35) 0;
    }

    p {
      font-family: $narkiss-medium-font;
      font-size: vw_d(42);
      line-height: vw_d(42);
      font-weight: 500;
      text-align: center;

      @include screen('mobile') {
        font-size: vw(21);
        line-height: vw(21);
      }

      span {
        display: inline-block;
        padding: vw_d(22) 0 vw_d(30);
        font-family: $narkiss-regular-font;
        font-size: vw_d(35);
        line-height: vw_d(35);
        font-weight: 400;

        @include screen('mobile') {
          font-size: vw(16);
          line-height: vw(16);
        }
      }
    }

    .external-link {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: vw_d(256);
      min-width: vw_d(256);
      height: vw_d(39);
      color: #fff;
      font-size: vw_d(24);
      font-family: $narkiss-bold-font;
      background-color: #e31b23;
      border-radius: vw_d(20);
      @include screen('mobile') {
        width: 100%;
        height: vw(45);
        font-size: vw(30);
        border-radius: vw(23);
      }

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }

    .close-icon {
      position: absolute;
      fill: #0099d8;
      cursor: pointer;
      top: vw_d(2);
      left: vw_d(2);

      @include screen('mobile') {
        top: vw(2);
        left: vw(2);
      }

      svg {
        width: vw_d(35);
        height: vw_d(35);

        @include screen('mobile') {
          width: vw(23.4);
          height: vw(23.4);
        }

        path {
          fill: #0099d8;
        }
      }
    }
  }
}
