@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  height: vw_d(30);
  width: 100%;

  @include screen('mobile') {
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding-bottom: vw(31);
    padding-right: vw(8);
  }

  label {
    display: flex;
    align-items: center;
  }

  .input-wrapper {
    position: relative;
    min-width: vw_d(14);
    width: vw_d(14);
    height: vw_d(14);
    margin: 0;
    margin-left: vw_d(8);
    @include screen('mobile') {
      width: vw(13);
      min-width: vw(13);
      height: vw(13);
      margin-top: vw(-17);
      margin-left: vw(10);
    }
  }

  input {
    position: relative;
    cursor: pointer;
    width: 0;
    height: 0;
    flex-shrink: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;

    @include screen('mobile') {
      width: 0;
      height: 0;
    }

    &:checked + span {
      &:after {
        opacity: 1;
      }
    }
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: vw_d(2);
    border: solid 2px #000;
    @include screen('mobile') {
      width: vw(13);
      height: vw(13);
      border-radius: vw(2);
      border: solid 1px #000;
    }
    &:after {
      content: '';
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: vw_d(8);
      height: vw_d(5);
      border: solid vw_d(2) $black;
      border-right: 0;
      border-top: 0;
      opacity: 0;
      @include screen('mobile') {
        width: vw(8);
        height: vw(5);
        border: solid vw(2) $black;
        border-right: 0;
        border-top: 0;
      }
    }
  }
  .confirmation {
    font-family: $narkiss-bold-font;
    font-size: vw_d(14);
    line-height: vw_d(14);
    color: $black;

    @include screen('mobile') {
      font-size: vw(14);
      line-height: vw(18.43);
    }
    a {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        @include screen('mobile') {
          text-decoration: underline;
        }
      }
    }
  }

  .error {
    color: red;
    font-size: vw_d(14);
    margin-right: vw_d(5);
    font-family: $narkiss-regular-font;
    display: block;
    width: 100%;
    position: absolute;
    bottom: vw_d(-20);

    @include screen('mobile') {
      font-size: vw(14);
      margin-right: vw(5);
      bottom: vw(8);
    }
  }
}
