@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: vw_d(79);
  background-color: rgba(255, 255, 255, 1);
  z-index: 4;
  @include screen('mobile') {
    height: vw(49);
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 vw_d(30.6) 0 vw_d(45);
    @include screen('mobile') {
      padding: 0 vw(10.3) 0 vw(10);
    }

    .logos-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include screen('mobile') {
      }
      a {
        &:first-child {
          width: vw_d(107.8);
          @include screen('mobile') {
            width: vw(56.7);
          }
        }
        &:last-child {
          width: vw_d(101);
          margin-right: vw_d(31.6);
          @include screen('mobile') {
            width: vw(53.1);
            margin-right: vw(14.9);
          }
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .socialls {
    display: flex;
    @include screen('mobile') {
    }
    .social-button {
      width: vw_d(32);
      margin-right: vw_d(22);
      cursor: pointer;
      @include screen('mobile') {
        width: vw(26.5);
        margin-right: vw(11.87);

        &:nth-child(2) {
          margin-right: vw(10);
        }
      }
      &:first-child {
        @include screen('mobile') {
          margin-right: 0;
        }
      }
      &:hover {
        opacity: 0.8;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}

:global {
  body {
    &.acc-grayscale,
    &.acc-negative {
      overflow-x: hidden;
    }
  }

  div#accessibility {
    position: absolute !important;
    z-index: 45 !important;
    border: 0 !important;
    background: transparent !important;
    box-shadow: none !important;

    @include screen('non-mobile') {
      top: 29px !important;

      html[dir='ltr'] & {
        right: 65px !important;
        left: auto !important;
      }

      html[dir='rtl'] & {
        right: auto !important;
        left: 65px !important;
      }
    }

    @include screen('mobile') {
      top: auto !important;
      right: vw(10 * 2) !important;
      bottom: vw(15 * 2) !important;
      left: auto !important;
      z-index: 45 !important;
    }

    div#acc-header {
      .image {
        background: none !important;

        @include screen('mobile') {
          right: 0 !important;
          left: auto !important;

          html[dir='rtl'] & {
            right: 0 !important;
            left: auto !important;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 25px;
          height: 25px;
          transform: translate(-50%, -50%);
        }

        html[dir='ltr'] & {
          right: 0 !important;
          left: auto !important;
        }

        html[dir='rtl'] & {
          right: auto !important;
          left: 0 !important;
        }

        img {
          display: none !important;
        }
      }

      .text {
        visibility: hidden;
      }
    }

    button#acc-regular {
      @include screen('non-mobile') {
        &:focus {
          outline: transparent auto 5px !important;
        }
      }
    }

    div#acc-footer {
      display: none !important;
    }
  }

  input,
  textarea {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #f6f6f6 inset;
      color: black;
    }
  }

  .overflow {
    overflow: hidden;
  }

  #INDmenu-btn {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      // background-color: red;
      // border: 1px solid red;

      @include screen('mobile') {
        width: vw(23 * 2);
        height: vw(23 * 2);
        background-color: white;
      }
    }
  }

  #INDbtnWrap {
    #INDmenu-btn {
      position: fixed;
      top: vw_d(20) !important;
      right: initial;
      left: vw_d(20) !important;
      width: vw_d(40);
      height: vw_d(40);
      margin: 0;
      transition: none !important;
      opacity: 0 !important;
      visibility: hidden !important;

      @include screen('mobile') {
        position: absolute;
        top: vw(19) !important;
        left: vw(20) !important;
        width: vw(44);
        height: vw(44);
      }
    }
  }

  #INDmenu-btn svg {
    visibility: hidden;
  }
}
